import { Box } from '@chakra-ui/react';
import {
  useTranslation,
  AELink,
  useTrans,
  useGetAELink,
  useGetDomain,
} from '@ae/shared';
import { InsideTextLink } from '@ae/shared-ui';

const HeaderTopBar = () => {
  const { t } = useTranslation('header');
  const Trans = useTrans();
  const { getLinkHref } = useGetAELink();
  const { isBeProdDomain } = useGetDomain();

  const AllStaysLink = isBeProdDomain
    ? getLinkHref(AELink.AllStaysBe)
    : getLinkHref(AELink.AllStaysNl);

  return (
    <Box
      background="ae.orange_100"
      padding="12px 1rem"
      fontSize={14}
      textAlign="center"
    >
      <Trans t={t} i18nKey="header.topBar.msg">
        text content
        <InsideTextLink target="_self" href={AllStaysLink}>
          all stays top bar
        </InsideTextLink>
      </Trans>
    </Box>
  );
};

export default HeaderTopBar;
