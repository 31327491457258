import { Box, HStack, Icon, Radio, Text, VStack } from '@chakra-ui/react';
import { useAssets } from '@ae/shared';

type RadioProps = {
  label: string;
  label2?: string;
  value: string;
  variant?: string;
  icon?: React.ReactElement<typeof Icon>;
  disabled?: boolean;
  isChecked?: boolean;
  onChange?: (value: string) => void;
};

export const UIRadioButton = ({
  label,
  label2,
  value,
  variant,
  icon,
  disabled = false,
  isChecked,
  onChange,
}: RadioProps) => {
  const { cssUrl } = useAssets();
  let borderRadius = '5px';
  if (variant === 'round') {
    borderRadius = '50%';
  }

  return (
    <Radio
      position="relative"
      variant={variant}
      value={value}
      borderRadius={borderRadius}
      borderWidth="1px"
      borderColor="ae.green"
      isDisabled={disabled}
      size="lg"
      isChecked={isChecked}
      onChange={() => onChange && onChange(value)}
      _checked={{
        borderColor: 'ae.pink',
        backgroundColor: 'ae.pink',
        backgroundImage: cssUrl('/shared/images/icon/checkbox-radio-g.svg'),
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <HStack spacing={0}>
        {icon && (
          <Box mr="10px" display="inline-block">
            {icon}
          </Box>
        )}
        <VStack alignItems="flex-start" spacing={0}>
          <Text fontSize="16px">{label}</Text>
          {label2 && <Text fontSize="16px">{label2}</Text>}
        </VStack>
      </HStack>
    </Radio>
  );
};
