import { Locale, useLanguage } from '@ae/i18n';
import { useCallback } from 'react';

import {
  BookingJsonldReadBookingState,
  useApiBookingsGetCollection,
} from '@ae/data-access';
import { LinkGetter, useGetDomain, useLink, useMe } from '../hooks';
import { useDate, useTranslation } from '@ae/shared';

export enum AELink {
  AllStaysBe,
  AllStaysNl,
  Home,
  About,
  BecomeOwner,
  Blog,
  BlogOwner,
  Contact,
  HelperCenterContact,
  Conditions,
  ConfidentialPolicy,
  FAQ,
  GeneralRegulation,
  GiftCard,
  HelpCenter,
  Jobs,
  Insurance,
  Dsa,
  InsuranceCancellation,
  InsuranceDamage,
  Logout,
  Owner,
  Search,
  VoucherFaq,
  VoucherOrder,
  Nature,
  Cycling,
  Fishing,
  Relaxation,
  Baby,
  Children,
  UnforgettableExperiences,
  WalkingTrail,
  Chalets,
  Castles,
  LuxuryVillas,
  UnusualAccommodations,
  CharmingCottage,
  IndoorPool,
  OutdoorPool,
  Swimmingpool,
  HotTub,
  Sauna,
  Inspiration,
  RegionsArdennes,
  Durbuy,
  Malmedy,
  LaRoche,
  Houffalize,
  EauDheure,
  Dinant,
  Namur,
  Bouillon,
  Spa,

  New,
  Summer,
  Wellness,
  Fireplace,
  TerraceBreathtakingView,
  MayCampagne,
  DurbuyThematic,
  RomanticWeekend,
  Ascension,
  PrivateGarden,
  EntertainmentStay,
  CampagneActionPromo,
}

export const useGetAELink = () => {
  const lng = useLanguage();
  const { dayjs } = useDate();
  const { t } = useTranslation('footer', 'home');
  const { prodDomain, apiDomain, getBeProdDomain, beProdDomain } =
    useGetDomain();
  const { authenticated } = useMe();
  const { data: bookings } = useApiBookingsGetCollection({
    query: { enabled: authenticated },
  });

  const getLink = useCallback(
    (link: AELink): LinkGetter => {
      switch (link) {
        case AELink.Home:
          return { href: `${beProdDomain}` };
        case AELink.About:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                case 'nl':
                case 'en':
                  return `${prodDomain}/made-in-ardenne`;
                case 'de':
                  return `${prodDomain}/made-in-ardennen`;

                default:
                  return '';
              }
            },
            title: t('footer.about'),
            alt: t('footer.about'),
          };
        case AELink.BecomeOwner:
          return {
            href: `${prodDomain}/newlead.do`,
            title: t('footer.become_owner'),
            alt: t('footer.become_owner'),
          };
        case AELink.Blog:
          return {
            href: () => {
              switch (lng) {
                case 'nl':
                  return `${getBeProdDomain(Locale.Nl)}/experience/blog`;
                case 'fr':
                  return `${getBeProdDomain(Locale.Fr)}/experience/blog`;
                case 'de':
                case 'en':
                default:
                  return `${getBeProdDomain(Locale.En)}/experience/blog`;
              }
            },
            target: '_blank',
          };
        case AELink.BlogOwner:
          return {
            href: () => {
              switch (lng) {
                case 'nl':
                case 'de':
                  return `${getBeProdDomain(Locale.Nl)}/blog-gite`;
                case 'fr':
                case 'en':
                default:
                  return `${getBeProdDomain(Locale.Fr)}/blog-gite`;
              }
            },
            title: t('footer.i_run_my_holiday_home'),
            alt: t('footer.i_run_my_holiday_home'),
            target: '_blank',
          };
        case AELink.Contact:
          return {
            href: `${prodDomain}/contact`,
            title: t('footer.contact_us'),
            alt: t('footer.contact_us'),
          };
        case AELink.HelperCenterContact:
          return {
            href: `${prodDomain}/help-center#helpcenterContact`,
            title: t('footer.contact_us'),
            alt: t('footer.contact_us'),
          };
        case AELink.Conditions:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/conditions-generales`;
                case 'nl':
                  return `${prodDomain}/algemene-voorwaarden`;
                case 'en':
                  return `${prodDomain}/general-conditions`;
                case 'de':
                  return `${prodDomain}/allgemeine-bedingungen`;
                default:
                  return '';
              }
            },
            title: t('footer.general_conditions'),
            alt: t('footer.general_conditions'),
          };
        case AELink.AllStaysBe:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `https://bit.ly/3Nhny1A`;
                case 'nl':
                  return `https://bit.ly/3Ng5TqN`;
                case 'en':
                case 'de':
                default:
                  return '';
              }
            },
          };
        case AELink.AllStaysNl:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                case 'nl':
                  return `https://bit.ly/3zO6SeM`;
                case 'en':
                case 'de':
                default:
                  return '';
              }
            },
          };
        case AELink.ConfidentialPolicy:
          return {
            href: `${prodDomain}/privacy-policy`,
            title: t('footer.privacy_policy'),
            alt: t('footer.privacy_policy'),
          };

        case AELink.GiftCard:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/cheque-cadeau`;
                case 'nl':
                  return `${prodDomain}/cadeaubon-bestellen`;
                case 'de':
                  return `${prodDomain}/geschenkgutschein-bestellen`;
                case 'en':
                  return `${prodDomain}/gift-vouchers`;
                default:
                  return '';
              }
            },
            title: t('footer.offer_a_gift'),
            alt: t('footer.offer_a_gift'),
            trackingname: 'order_voucher',
          };
        case AELink.HelpCenter:
          return {
            href: () => {
              const hasFutureBooking = bookings?.['hydra:member']?.some(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (b: any) =>
                  b.state !== BookingJsonldReadBookingState.canceled &&
                  dayjs(b.endDate).isAfter(dayjs())
              );

              const url = `${prodDomain}/help-center`;

              if (!authenticated) {
                return url;
              }

              switch (lng) {
                case 'fr':
                  return hasFutureBooking
                    ? `${url}/2-ma-reservation`
                    : `${url}/1-recherche-d'un-sejour`;

                case 'nl':
                  return hasFutureBooking
                    ? `${url}/2-mijn-reservatie`
                    : `${url}/1-op-zoek-naar-een-verblijf`;

                case 'de':
                  return hasFutureBooking
                    ? `${url}/2-meine-buchung`
                    : `${url}/1-suche-nach-einem-aufenthalt`;

                case 'en':
                default:
                  return hasFutureBooking
                    ? `${url}/2-manage-my-booking`
                    : `${url}/1-looking-for-a-stay`;
              }
            },
          };
        case AELink.FAQ:
          return {
            href: `${prodDomain}/help-center`,
            title: t('footer.faq'),
            alt: t('footer.faq'),
          };
        case AELink.Jobs:
          return {
            href: `${prodDomain}/jobs`,
            title: t('footer.jobs'),
            alt: t('footer.jobs'),
          };
        case AELink.Dsa:
          return {
            href: `${prodDomain}/dsa`,
            title: t('footer.dsa'),
            alt: t('footer.dsa'),
          };
        case AELink.InsuranceCancellation:
        case AELink.Insurance:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/assurances`;
                case 'nl':
                  return `${prodDomain}/verzekeringen`;
                case 'de':
                  return `${prodDomain}/versicherungen`;
                case 'en':
                  return `${prodDomain}/insurance`;
                default:
                  return '';
              }
            },
            title: t('footer.insurance'),
            alt: t('footer.insurance'),
          };
        case AELink.Nature:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/experience/decouvrez-la-nature-intacte-de-2-parcs-nationaux-en-ardenne`;
                case 'nl':
                  return `${prodDomain}/experience/ontdek-de-ongerepte-natuur-in-2-nationale-parken-in-de-ardennen`;
                case 'de':
                  return `${prodDomain}/experience/discover-the-unspoilt-nature-of-two-ardennes-national-parks`;
                case 'en':
                  return `${prodDomain}/experience/discover-the-unspoilt-nature-of-two-ardennes-national-parks`;
                default:
                  return '';
              }
            },
          };
        case AELink.Cycling:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/experience/top-12-gites-velo-friendly-en-ardenne`;
                case 'nl':
                  return `${prodDomain}/experience/news/top-12-fietsvriendelijke-vakantiehuizen-de-ardennen`;
                case 'de':
                  return `${prodDomain}/experience/top-12-bike-friendly-holiday-homes-in-the-ardennes`;
                case 'en':
                  return `${prodDomain}/experience/top-12-bike-friendly-holiday-homes-in-the-ardennes`;
                default:
                  return '';
              }
            },
          };

        case AELink.Fishing:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/pecher-en-ardenne`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/vissen-in-de-ardennen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/angeln-in-den-ardennen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/fishing-in-the-ardennes`;
                default:
                  return '';
              }
            },
          };

        case AELink.Baby:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/sejour-avec-bebe`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/verblijf-met-baby`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/aufenthalt-mit-baby`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/stay-with-baby`;
                default:
                  return '';
              }
            },
          };

        case AELink.Relaxation:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/se-ressourcer-en-ardenne`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/onthaasten-in-de-ardennen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/entschleunigen-in-den-ardennen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/relaxing-in-the-ardennes`;
                default:
                  return '';
              }
            },
          };

        case AELink.Children:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/sejour-avec-enfants`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/verblijf-met-kinderen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/aufenthalt-mit-kindern`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/stay-with-kids`;
                default:
                  return '';
              }
            },
          };

        case AELink.WalkingTrail:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/experience/balades?cities=all&field_type=All`;
                case 'nl':
                  return `${prodDomain}/experience/wandelingen?cities=all&field_type=All`;
                case 'de':
                  return `${prodDomain}/experience/hikes?cities=all&field_type=All`;
                case 'en':
                  return `${prodDomain}/experience/hikes?cities=all&field_type=All`;
                default:
                  return '';
              }
            },
          };

        case AELink.UnforgettableExperiences:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/experience/recherche?cities=all&combine=&field_category_target_id=All`;
                case 'nl':
                  return `${prodDomain}/experience/zoek?cities=all&combine=&field_category_target_id=All`;
                case 'de':
                  return `${prodDomain}/experience/search?cities=all&combine=&field_category_target_id=All`;
                case 'en':
                  return `${prodDomain}/experience/search?cities=all&combine=&field_category_target_id=All`;
                default:
                  return '';
              }
            },
          };

        case AELink.Chalets:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Chalets`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Chalets`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Chalets`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Chalets`;
                default:
                  return '';
              }
            },
          };
        case AELink.Castles:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/chateaux`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/kastelen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/schlosser`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/castles`;
                default:
                  return '';
              }
            },
          };
        case AELink.LuxuryVillas:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/villas-de-luxe`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/luxe-villas`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/luxusvillen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/luxury-villas`;
                default:
                  return '';
              }
            },
          };
        case AELink.UnusualAccommodations:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/week-end-insolite`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/origneel-overnachten`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/originell-ubernachten`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/original-accommodation`;
                default:
                  return '';
              }
            },
          };
        case AELink.CharmingCottage:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/gites-de-charme`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/huisjes`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/cottages`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/cottages`;
                default:
                  return '';
              }
            },
          };
        case AELink.IndoorPool:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/piscine-interieure`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/binnenzwembad`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/schwimmbad-innen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/indoor-pool`;
                default:
                  return '';
              }
            },
          };

        case AELink.Swimmingpool:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/gites-avec-piscine`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/vakantiehuizen-met-zwembad`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/ferienhauser-mit-schwimmbad`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/holiday-houses-with-swimmingpool`;
                default:
                  return '';
              }
            },
          };

        case AELink.New:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/nouveautes`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/nieuw`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/neu`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/new`;
                default:
                  return '';
              }
            },

            alt: t('home:home.campagne.title'),
          };
        case AELink.Ascension:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/10-99p/Week-end/2025-05-28_to_2025-06-01/369_maisons-de-vacances-en-Ardenne?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/10-99p/Weekend/2025-05-28_to_2025-06-01/369_vakantiehuizen-in-de-Ardennen?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/10-99p/Wochenende/2025-05-28_to_2025-06-01/369_ferienwohnungen-in-den-Ardennen/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/10-99p/Weekend/2025-05-28_to_2025-06-01/369_holiday-Houses-in-the-Ardennes?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
          };

        case AELink.PrivateGarden:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/0000-00-00/3_Confort/jardin-prive/barbecue/les-mieux-notees/?TriRecherche=Prix-ASC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/0000-00-00/3_Comfort/de-best-beoordeelde/privetuin/barbecue/?TriRecherche=Prix-ASC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/0000-00-00/3_Komfort/privater-garten/grill/bestbewerteten/?TriRecherche=Prix-ASC`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/0000-00-00/3_Comfort/top-rated/private-garden/barbecue/?TriRecherche=Prix-ASC`;
                default:
                  return '';
              }
            },
          };

        case AELink.Summer:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/vacances-ete`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/zomervakantie`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/urlaub-sommerferien`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/summer-holidays`;
                default:
                  return '';
              }
            },
          };

        case AELink.RomanticWeekend:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/week-end-en-amoureux`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/romantisch-weekendje-weg`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/romantikwochenende`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/romantic-weekend-breaks`;
                default:
                  return '';
              }
            },
          };

        case AELink.EntertainmentStay:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/sejour-divertissement`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/pretgarantie`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/aufenthalt-unterhaltung`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/entertainment-stay`;
                default:
                  return '';
              }
            },
          };

        case AELink.Wellness:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/maisons-de-vacances-ardennes/Vacances-wellness-en-Ardenne`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Wellness-vakantie-in-de-Ardennen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Wellnesurlaub-in-den-Ardennen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Wellness-vacation-in-the-Ardennes`;
                default:
                  return '';
              }
            },
          };

        case AELink.Fireplace:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/2-60p/Week-end/2024-04-00/Feu-ouvert/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/search/2-60p/Weekend/2024-04-00/Open-haard/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/2-60p/Wochenende/2024-04-00/Kamin/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/2-60p/Weekend/2024-04-00/Fireplace/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
          };
        case AELink.OutdoorPool:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/piscine-exterieure`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/buitenzwembad`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/schwimmbad-aussen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/outdoor-pool`;
                default:
                  return '';
              }
            },
          };
        case AELink.HotTub:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Vacances-Jacuzzi`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Vakantie-Jacuzzi`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Ferien-Jacuzzi`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Holidays-Jacuzzi`;
                default:
                  return '';
              }
            },
          };

        case AELink.CampagneActionPromo:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Vacances-Jacuzzi`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Vakantie-Jacuzzi`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Ferien-Jacuzzi`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Holidays-Jacuzzi`;
                default:
                  return '';
              }
            },
          };
        case AELink.Sauna:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Sejour-avec-Sauna`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Verblijven-met-Sauna`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Aufenthalt-mit-Sauna`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Stays-with-Sauna`;
                default:
                  return '';
              }
            },
          };

        case AELink.Inspiration:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/vacances-idees-et-bons-plans`;
                case 'nl':
                  return `${prodDomain}/vakantie-ideeen-advies-en-inspiratie`;
                case 'de':
                  return `${prodDomain}/urlaub-ideen-und-tipps`;
                case 'en':
                  return `${prodDomain}/holiday-ideas-advice-and-inspiration`;
                default:
                  return '';
              }
            },
          };
        case AELink.RegionsArdennes:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/experience/article/decouvrez-ces-8-regions-en-ardenne`;
                case 'nl':
                  return `${prodDomain}/experience/artikel/ontdek-deze-8-zones-de-ardennen`;
                default:
                  return '';
              }
            },
          };

        case AELink.Durbuy:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Durbuy`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Durbuy`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Durbuy`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Durbuy`;
                default:
                  return '';
              }
            },
          };
        case AELink.DurbuyThematic:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/2-60p/Week-end/0000-00-00/10_Distance/45_maisons-de-vacances-en-Ardenne/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/2-60p/Weekend/0000-00-00/10_Afstand/45_vakantiehuizen-in-de-Ardennen/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/2-60p/Wochenende/0000-00-00/10_Entfernung/45_ferienwohnungen-in-den-Ardennen/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/2-60p/Weekend/0000-00-00/10_Distance/45_holiday-Houses-in-the-Ardennes/?TriRecherche=Satisfaction-DESC `;
                default:
                  return '';
              }
            },
          };
        case AELink.Malmedy:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Malmedy`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Malmedy`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Malmedy`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Malmedy`;
                default:
                  return '';
              }
            },
          };
        case AELink.Houffalize:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Houffalize`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Houffalize`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Houffalize`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Houffalize`;
                default:
                  return '';
              }
            },
          };
        case AELink.LaRoche:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Laroche`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Laroche`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Laroche`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Laroche`;
                default:
                  return '';
              }
            },
          };
        case AELink.EauDheure:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Froidchapelle`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Froidchapelle`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Froidchapelle`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Froidchapelle`;
                default:
                  return '';
              }
            },
          };
        case AELink.Dinant:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Dinant`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Dinant`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Dinant`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Dinant`;
                default:
                  return '';
              }
            },
          };
        case AELink.Namur:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Namur`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Namen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Namur`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Namur`;
                default:
                  return '';
              }
            },
          };
        case AELink.Bouillon:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Bouillon`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Bouillon`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Bouillon`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Bouillon`;
                default:
                  return '';
              }
            },
          };
        case AELink.Spa:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Spa`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Spa`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Spa`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Spa`;
                default:
                  return '';
              }
            },
          };

        case AELink.TerraceBreathtakingView:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/terrasse-vue-imprenable`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/terras-met-uniek-uitzicht`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/terrasse-blick`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/terrace-breathtaking-view`;
                default:
                  return '';
              }
            },
          };

        case AELink.MayCampagne:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/8-20p/Week-end/2024-05-00/4-30_Chambres/2-30_Salle_de_bain/3_Confort/barbecue/jardin-prive/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/8-20p/Weekend/2024-05-00/4-30_Kamers/2-30_Badkamer/3_Comfort/barbecue/privetuin?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/8-20p/Wochenende/2024-05-00/4-30_Zimmer/2-30_Badezimmer/3_Komfort/grill/privater-garten/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/8-20p/Weekend/2024-05-00/4-30_Rooms/2-30_Bathroom/3_Comfort/barbecue/private-garden/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
          };

        case AELink.InsuranceDamage:
          return {
            href: () =>
              `${prodDomain}/help-center/2-ma-reservation/114-que-faire-en-cas-de-degats`,
            title: t('footer.insurance'),
            alt: t('footer.insurance'),
          };
        case AELink.Logout:
          return {
            href: `${apiDomain}/auth/logout?target=${encodeURIComponent(
              `${prodDomain}?logmeout=1`
            )}`,
          };
        case AELink.Owner:
          return {
            href: () => {
              switch (lng) {
                case 'nl':
                  return `${prodDomain}/proprietaires/nl/index.do`;
                case 'fr':
                case 'de':
                case 'en':
                default:
                  return `${prodDomain}/proprietaires/fr/index.do`;
              }
            },
            title: t('footer.my_host_account'),
            alt: t('footer.my_host_account'),
          };
        case AELink.GeneralRegulation:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/ressource/pdf/AE_2013-08_ReglementOrdreINT.pdf`;
                case 'nl':
                  return `${prodDomain}/ressource/pdf/AE_2013-08_ReglementOrdreINT_NL.pdf`;
                case 'de':
                  return `${prodDomain}/ressource/pdf/AE_2013-08_ReglementOrdreINT_DE.pdf`;
                case 'en':
                default:
                  return `${prodDomain}/ressource/pdf/AE_2013-08_ReglementOrdreINT_EN.pdf`;
              }
            },
          };
        case AELink.Search:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `/recherche`;
                case 'nl':
                  return `/zoeken`;
                case 'de':
                  return `/suchen`;
                case 'en':
                default:
                  return `/search`;
              }
            },
          };
        case AELink.VoucherFaq:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/help-center/4-covid-19/49-comment-utiliser-votre-bon-a-valoir`;
                case 'nl':
                  return `${prodDomain}/help-center/4-covid-19/49-hoe-dient-u-te-boeken-met-een-tegoedbon`;
                case 'de':
                  return `${prodDomain}/help-center/4-covid/49-wie-losen-sie-ihren-gutschein-ei`;
                case 'en':
                default:
                  return `${prodDomain}/help-center/4-covid-19/49-how-to-use-your-voucher`;
              }
            },
          };
        case AELink.VoucherOrder:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/cheque-cadeau`;
                case 'nl':
                  return `${prodDomain}/cadeaubon-bestellen`;
                case 'de':
                  return `${prodDomain}/geschenkgutschein-bestellen`;
                case 'en':
                default:
                  return `${prodDomain}/gift-vouchers`;
              }
            },
          };
        default:
          throw Error('link not found');
      }
    },
    [
      apiDomain,
      authenticated,
      bookings,
      dayjs,
      getBeProdDomain,
      lng,
      prodDomain,
      t,
    ]
  );

  return useLink<AELink>(getLink);
};
