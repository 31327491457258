import { extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './components/buttonTheme';
import { checkboxTheme } from './components/checkboxTheme';
import { headingTheme } from './components/headingTheme';
import { switchTheme } from './components/switchTheme';
import { accordionTheme } from './components/accordionTheme';

import '@fontsource/lato';
import '@fontsource/lato/400.css';
import '@fontsource/lato/400-italic.css';

import '@fontsource/dm-serif-text';
import '@fontsource/dm-serif-text/400.css';
import '@fontsource/dm-serif-text/400-italic.css';

export const sharedTheme = extendTheme({
  fonts: {
    heading: 'DM Serif Text',
    body: 'Lato',
  },
  breakpoints: {
    // base: From 0em upwards
    sm: '30em',
    md: '56em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  sizes: {
    container: {
      xl: '1196px',
    },
  },
  colors: {
    ae: {
      white: '#ffffff',
      whiteAlpha20: 'rgba(255,255,255,0.2)',
      whiteAlpha40: 'rgba(255,255,255,0.4)',
      whiteAlpha60: 'rgba(255,255,255,0.6)',
      whiteAlpha80: 'rgba(255,255,255,0.8)',

      orange: '#D96F0E',
      orange_100: '#e9a281',
      yellow: '#FFC000',
      green: '#003f35',
      green_100: '#E5ECEB',
      green_200: '#CCD9D7',
      green_400: '#99B2AE',
      green_600: '#668C86',
      green_800: '#33655D',

      pink: '#fbd7c9',
      pink_200: '#FEF7F4',
      pink_400: '#FDEFE9',
      pink_600: '#FDE7DF',
      pink_800: '#FCDFD4',

      black: '#1d1d1b',
      grey_100: '#f1f1f1',
      grey_200: '#DCDCDC',
      grey_300: '#c4c4c4',
      grey_400: '#707070',
      greyE5: '#E5E5E5',
      grey97: '#979797',
      red: '#B42025',
      success: '#9CC813',
      warning: '#ffc000',
      transparent: 'rgba(0,0,0,0)',
      modalBg: 'rgba(0,63,53,0.32)',
      imageFilter: 'rgba(0, 63, 53, 0.35)',
    },

    error: {
      100: '#FFEFF1',
      400: '#d9878b',
      900: '#B42025',
    },
    success: {
      100: '#d6dfbb',
      400: '#9cc813',
      900: '#9CC813',
    },
    warning: {
      100: '#f2ebce',
      400: '#f8d567',
      900: '#ffc000',
    },
    checkBox: {
      500: '#fbd7c9', // you need this
    },
  },
  shadows: {
    base: '0px 2px 3px rgba(0, 0, 0, 0.119509), 0px 8px 14px rgba(74, 74, 87, 0.08)',
    blur: '0px 0px 5px rgba(0, 0, 0, 0.119509), 0px 0px 14px rgba(74, 74, 87, 0.08)',
    level_1: '0px 6px 6px 0px #003F351A',
    level_2: '0px 12px 12px 0px #003F351A',
    level_3: '0px 18px 18px 0px #003F351A',
  },
  radii: {
    base: '5px',
    box: '8px',
    button: '20px',
  },
  components: {
    Heading: headingTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Switch: switchTheme,
    Accordion: accordionTheme,
    Alert: {
      baseStyle: {
        container: {
          borderRadius: '4px',
        },
      },
    },
  },
});
